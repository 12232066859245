
   export default {
     components:{
     },
     data(){
       return {
       }
     },
     beforeCreate(){
       
     },
     head() {
       
     }
   }
 