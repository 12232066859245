
  export default {
    data() {
      return {
        list: [{
           img: 'index_banner_1'
        }, {
           img: 'index_banner_2'
        }, {
           img: 'index_banner_3'
        }],
        activeIndex: 0,
      }
    },
    mounted(){
       setInterval(()=>{
          this.onNext();
       },5000);
    },
    methods: {
      onPrev() {
        this.activeIndex--;
        if (this.activeIndex < 0) {
          this.activeIndex = this.list.length - 1;
        }
      },
      onNext() {
        this.activeIndex++;
        if (this.activeIndex > this.list.length - 1) {
          this.activeIndex = 0;
        }
      },
      onCurrent(index){
        this.activeIndex=index;
      },
    }
  }
