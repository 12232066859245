
  export default{
     name: 'ProductPage',
     data(){
       return{
          cateList: [{
              key: "square",
              selected: true,
              name: "不锈钢消防水箱",
            },
            {
              key: "warm",
              name: "不锈钢保温水箱",
            },
            {
              key: "life",
              name: "不锈钢生活水箱",
            },
            {
              key:"ic",
              name: "巧克力水箱",
            },
            {
              key:"clean",
              name: "不锈钢承压水箱",
            },
            {
              key:"bdf",
              name: "BDF水箱",
            },
            {
              key:"land",
              name: "地埋式水箱",
            },
            {
              key:"cycle",
              name: "圆柱形水箱",
            },
            {
              key:"space",
              name: "油水分离器",
            },
            {
              key:"room",
              name: "一体化智慧泵房",
            },
            {
              key:"clear",
              name: "水箱消毒器",
            },
            {
              key:"lift",
              name: "污水提升设备",
            },
            {
              key:"provide",
              name: "无负压供水设备",
            }
          ],
          productList: [],
          productTmp:{},
       }
     },
     async asyncData(context) {
       let productData = await context.$request.productMgr.getCateType("square");

       return {
         productList:productData.list || [],
       }
     },
     methods:{
       onCate(item) {
         for (let cate of this.cateList) {
           this.$set(cate, "selected", false);
         }
         this.$set(item, "selected", true);

         if (!this.productTmp[item.key]) {
           this.getProduct(item.key);
         } else {
           this.productList = this.productTmp[item.key].list || [];
         }
       },
       getProduct(key) {
         this.$request.productMgr.getCateType(key).then((result) => {
           this.productList = result.list || [];
           this.productTmp[key] = result || {};
         });
       },
     },
  }
