
  export default{
    name: 'AnswerPage',
    data(){
      return{
        list:[],
      }
    },
    methods:{

    },
  }
