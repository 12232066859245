
  export default {
     data(){
       return{
         commonStyle: {
         		position: 'absolute',
         		width: 200,
         		height: 50,
         		top: 20,
         		left: 20,
         		rotate: 0,
         		paddingTop: 0,
         		paddingLeft: 0,
         		paddingRight: 0,
         		paddingBottom: 0,
         		marginTop: 0,
         		marginLeft: 0,
         		marginRight: 0,
         		marginBottom: 0,
         		borderWidth: 0,
         		borderColor: '',
         		borderStyle: 'solid',
         		borderRadius: 0,
         		boxShadow: '',
         		fontSize: 16,
         		fontWeight: 500,
         		lineHeight: 1.4,
         		letterSpacing: 0,
         		textAlign: 'center',
         		color: '#000000',
         		backgroundColor: '',
         		backgroundImage: '',
         		backgroundSize: 'cover',
         		opacity: 1,
         		zIndex: 1
         	}
       }
     },
     mounted(){


     },
     methods:{
       handleElementClick(uuid){

       },
       handleElementResize(pos) {
          if (!pos) {
            return;
          }
          this.commonStyle.left = pos.left
          // 更新元素commonStyle
          this.commonStyle = {
            ...pos
          }
        },
     },
  }
