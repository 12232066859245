
  export default{
    name: 'NewsPage',
    data(){
      return{
        list:[],
      }
    },
    async asyncData(context) {
      let newsData=await context.$request.newsMgr.getList();

      let list=newsData.list.sort(function(a,b){
        if(a.date){
           return Date.parse(a.date.replace(/-/g,"/")) > Date.parse(b.date.replace(/-/g,"/")) ?-1:1;
        }
      });

      return {
        list:list,
      }
    },
    mounted(){
       //this.$request.newsMgr.getList();
    },
    methods:{

    }
  }
